


















































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Money } from 'v-money';
import { mask } from 'vue-the-mask';
import GeneralHelper from '@/shared/helpers/general';
import { IOffer, OfferType } from '@/types/offers';
import { frequenciesData, periodsData, contentAccess } from '@/libs/offers';
import MSwitch from '@/shared/components/MSwitch.vue';
import { PaymentMethodsList } from '@/types/payments';
import PaymentMethodSelect from '@/components/PaymentMethodSelect.vue';
import SalesStrategySection from '@/components/SalesStrategySection.vue';
import debounce from 'lodash.debounce';
import MInput from '@/components/MInput.vue';

@Component({
  components: {
    Money,
    MSwitch,
    PaymentMethodSelect,
    SalesStrategySection,
    MInput,
  },
  directives: { mask },
})
export default class OfferTabsStepOne extends Vue {
  @Prop({}) type: any;
  @Prop({}) value: IOffer;

  loading = false;
  isBasePriceMin = false;
  title: string = '';
  basePrice: number = 0;
  price: number = 0;
  period: string = '';
  frequency_limit: string = '';
  frequency_type: string = '';
  overdue_type: string = 'count_in_sequence';
  overdue_limit: string = '';
  allow_overdue_access: boolean = false;
  paymentMethod: string[] = [];
  installmentsAmountLimit: number = 1;
  showOnSellPage: boolean = false;
  frequencies: any = frequenciesData;
  periods: any = periodsData;
  contentAccessOptions: any = contentAccess;
  smartInstallmentEnabled: boolean = false;
  bankSlipInstallmentEnabled: boolean = false;
  pixInstallmentEnabled: boolean = false;
  bankSlipMaxInstallments: number = 1;
  pixMaxInstallments: number = 1;

  mounted() {
    this.showOnSellPage = this.value.default;
    this.basePrice = this.value.base_price;
    this.installmentsAmountLimit = this.value.installments_amount_limit;
    this.price = this.value.price;
    this.paymentMethod = this.value.payment_method;
    this.title = this.value.title ?? '';
    this.period = this.value.period || 'monthly';
    this.frequency_limit = this.value.frequency_limit || '';
    this.frequency_type = this.value.frequency_type || 'unlimited';
    this.overdue_type = 'count_in_sequence';
    this.overdue_limit = this.value.overdue_limit || '3';
    this.smartInstallmentEnabled = this.value.smart_installment;
    this.bankSlipInstallmentEnabled = this.value.bank_slip_installment;
    this.pixInstallmentEnabled = this.value.pix_installment;
    this.bankSlipMaxInstallments = this.value.bank_slip_max_installments || 1;
    this.pixMaxInstallments = this.value.pix_max_installments || 1;
    this.allow_overdue_access = this.value.allow_overdue_access || false;

    this.$emit('input', { ...this.value, overdue_type: 'count_in_sequence' });
  }

  checkPaymentMethodStrategiesEnabled() {
    if (!this.acceptsBankSlip) {
      this.bankSlipMaxInstallments = 1;
      this.bankSlipInstallmentEnabled = false;
    }

    if (!this.acceptsPix) {
      this.pixMaxInstallments = 1;
      this.pixInstallmentEnabled = false;
    }

    if (!this.acceptsCreditCard || this.installmentsAmountLimit === 1) {
      this.smartInstallmentEnabled = false;
    }
  }

  selectPaymentMethod(payment) {
    this.paymentMethod = payment;

    this.checkPaymentMethodStrategiesEnabled();
  }

  selectInstallment(installment) {
    this.installmentsAmountLimit = installment;
    this.checkPaymentMethodStrategiesEnabled();
  }

  selectSmartInstallmentEnabled(smartInstallmentEnabled) {
    this.smartInstallmentEnabled = smartInstallmentEnabled;
  }

  selectBankSlipInstallmentEnabled(bankSlipInstallmentEnabled) {
    this.bankSlipInstallmentEnabled = bankSlipInstallmentEnabled;
  }

  selectPixInstallmentEnabled(pixInstallmentEnabled) {
    this.pixInstallmentEnabled = pixInstallmentEnabled;
  }

  selectBankSlipMaxInstallments(bankSlipMaxInstallments) {
    this.bankSlipMaxInstallments = bankSlipMaxInstallments;
  }

  selectPixMaxInstallments(pixMaxInstallments) {
    this.pixMaxInstallments = pixMaxInstallments;
  }

  get disabledSmartInstallment() {
    return this.installmentsAmountLimit === 1 || !this.isCreditCardCommonOffer;
  }

  get enablePricingStep() {
    return this.title && this.price >= 1.99 && this.paymentMethod.length > 0;
  }

  get enableOverdueLimit() {
    return Number(this.overdue_limit) >= 2
  }

  get isCreditCardCommonOffer() {
    return this.acceptsCreditCard && this.isCommonOffer;
  }

  get canSubscriptionFrequency() {
    return this.$FCL.canSubscriptionFrequency();
  }

  get isSubscription() {
    return this.value.kind === OfferType.SUBSCRIPTION;
  }

  get isSparkcheckoutSubscription() {
    return this.isSubscription && this.hasSparkCheckout;
  }

  get hasSparkCheckout() {
    return this.$FCL.hasSparkCheckout();
  }

  get paymentMethodProps() {
    return this.value.payment_method.length ? this.value.payment_method : undefined;
  }

  get acceptsBankSlip() {
    return this.paymentMethod.includes(PaymentMethodsList.BANK_SLIP);
  }

  get acceptsPix() {
    return this.paymentMethod.includes(PaymentMethodsList.PIX);
  }

  get acceptsCreditCard() {
    return this.paymentMethod.includes(PaymentMethodsList.CREDIT_CARD);
  }

  get displaySalesStrategySection() {
    const { acceptsBankSlip, acceptsPix, acceptsCreditCard } = this;

    const hasPaymentMethod = acceptsBankSlip || acceptsPix || acceptsCreditCard;

    return this.isCommonOffer && hasPaymentMethod;
  }

  formatCurrency(value) {
    return value ? GeneralHelper.currency(value, false) : '0,00';
  }

  detectBasePrice() {
    this.basePrice = Math.max(this.price, this.basePrice);
  }

  setIsBasePriceMin() {
    this.isBasePriceMin = this.price > this.basePrice;
  }

  emitChangePrice() {
    this.$emit('input', { ...this.value, price: this.price });
  }
  canSubscriptionOverduePayments() {
    return this.$FCL.canSubscriptionOverduePayments();
  }

  trackOption(option) {
    let _option = option !== 'unlimited' ? option : '';
    this.$set(this, 'frequency_type', _option);
  }

  @Watch('title')
  onChangeTitle() {
    this.$emit('input', { ...this.value, title: this.title });
  }

  @Watch('basePrice')
  onChangeBasePrice() {
    this.$emit('input', { ...this.value, base_price: this.basePrice });
  }

  @Watch('price')
  onChangePrice = debounce(this.emitChangePrice, 600);

  @Watch('period')
  onChangePeriod() {
    this.$emit('input', { ...this.value, period: this.period });
  }

  @Watch('frequency_type')
  onChangeFrequencyType() {
    this.$emit('input', { ...this.value, frequency_type: this.frequency_type });
  }

  @Watch('frequency_limit')
  onChangeFrequencyLimit() {
    this.$emit('input', { ...this.value, frequency_limit: this.frequency_limit });
  }

  @Watch('overdue_limit')
  onChangeOverdueLimit() {
    this.$emit('input', { ...this.value, overdue_limit: this.overdue_limit });
  }

  @Watch('allow_overdue_access')
  onChangeAllowOverdueAccess() {
    this.$emit('input', { ...this.value, allow_overdue_access: this.allow_overdue_access });
  }

  @Watch('showOnSellPage')
  onShowOnSellPagePrice() {
    this.$emit('input', { ...this.value, default: this.showOnSellPage });
  }

  @Watch('paymentMethod')
  onChangePaymentMethod() {
    this.$emit('input', { ...this.value, payment_method: this.paymentMethod });
  }

  @Watch('installmentsAmountLimit')
  onChangeInstallmentAmountLimit() {
    this.$emit('input', { ...this.value, installments_amount_limit: this.installmentsAmountLimit });
  }

  @Watch('smartInstallmentEnabled')
  onChangeSmartInstallmentEnabled() {
    this.$emit('input', { ...this.value, smart_installment: this.smartInstallmentEnabled });
  }

  @Watch('bankSlipInstallmentEnabled')
  onChangeBankSlipInstallmentEnabled() {
    this.$emit('input', { ...this.value, bank_slip_installment: this.bankSlipInstallmentEnabled });
  }

  @Watch('pixInstallmentEnabled')
  onChangePixInstallmentEnabled() {
    this.$emit('input', { ...this.value, pix_installment: this.pixInstallmentEnabled });
  }

  @Watch('bankSlipMaxInstallments')
  onChangeBankSlipMaxInstallments() {
    this.$emit('input', { ...this.value, bank_slip_max_installments: this.bankSlipMaxInstallments });
  }

  @Watch('pixMaxInstallments')
  onChangePixMaxInstallments() {
    this.$emit('input', { ...this.value, pix_max_installments: this.pixMaxInstallments });
  }

  @Watch('enablePricingStep')
  onChangeEnableFacebookInputs(stepEnableStatus) {
    this.$emit('save-enabled', {
      step: 'step-one',
      status: stepEnableStatus,
    });
  }

  @Watch('enableOverdueLimit')
  onChangeEnableOverdueLimit(stepEnableStatus) {
    this.$emit('save-enabled', {
      step: 'step-one',
      status: stepEnableStatus,
    });
  }

  get isCommonOffer() {
    return this.type.kind === OfferType.COMMON;
  }

  get isNotFreeOffer() {
    return this.type.kind !== OfferType.FREE;
  }
}
