





















































































import { Component, Vue } from 'vue-property-decorator';
import { hsModal } from '@/components';

@Component({
  components: {
    hsModal,
  },
})
export default class SalesStrategyModal extends Vue {
  closeModal() {
    this.$bvModal.hide('sales-stragegy-modal');
  }
}
