













































































































import debug from 'debug';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IOffer } from '@/types/offers';
import { range } from 'lodash';
import PaymentService from '@/services/sparkecommerce/payments.js';
import MSwitch from '@/shared/components/MSwitch.vue';
import SalesStrategyModal from '@/components/SalesStrategyModal.vue';
import SelectInstallments from '@/components/SelectInstallments.vue';

const logging = debug('hs:sales-strategy-section');

@Component({
  components: {
    MSwitch,
    SalesStrategyModal,
    SelectInstallments,
  },
})
export default class SalesStrategySection extends Vue {
  @Prop({ required: true }) offer: IOffer;
  @Prop({ default: 1 }) bankSlipSelectedInstallments: number;
  @Prop({ default: 1 }) pixSelectedInstallments: number;
  @Prop({ default: false }) disabledSmartInstallment: boolean;
  @Prop({ default: false }) disabledBankSlipInstallment: boolean;
  @Prop({ default: false }) disabledPixInstallment: boolean;

  smartInstallmentEnabled: boolean = false;
  bankSlipInstallmentEnabled: boolean = false;
  pixInstallmentEnabled: boolean = false;
  pixPaymentSimulation: any[] = [];
  bankSlipPaymentSimulation: any[] = [];

  get offerPrice() {
    return this.offer.price;
  }

  get formattedPixInstallmentsBadgeItems() {
    return range(1, this.pixPaymentSimulation.length + 1).map(num => {
      return {
        text: `${num}x`,
        variant: num <= this.pixSelectedInstallments ? 'primary' : 'disabled',
        installments: num,
      };
    });
  }

  get formattedBankSlipInstallmentsBadgeItems() {
    return range(1, this.bankSlipPaymentSimulation.length + 1).map(num => {
      return {
        text: `${num}x`,
        variant: num <= this.bankSlipSelectedInstallments ? 'primary' : 'disabled',
        installments: num,
      };
    });
  }

  openSmartInstallmentModal() {
    this.$bvModal.show('sales-stragegy-modal');
  }

  mounted() {
    this.smartInstallmentEnabled = this.offer.smart_installment;
    this.bankSlipInstallmentEnabled = this.offer.bank_slip_installment;
    this.pixInstallmentEnabled = this.offer.pix_installment;
  }

  fetchBankSlipSimulation() {
    if (!this.offerPrice || !this.bankSlipInstallmentEnabled) return;

    PaymentService.payment_simulation({ amount: this.offerPrice, payment_method: 'bank_slip' })
      .then(simulation => {
        this.bankSlipPaymentSimulation = simulation;
        const minInstallments = Math.min(this.bankSlipSelectedInstallments, simulation.length || 1);
        this.$emit('select-bank-slip-max-installments', minInstallments);
      })
      .catch(error => logging(error));
  }

  fetchPixSimulation() {
    if (!this.offerPrice || !this.pixInstallmentEnabled) return;

    PaymentService.payment_simulation({ amount: this.offerPrice, payment_method: 'pix' })
      .then(simulation => {
        this.pixPaymentSimulation = simulation;
        const minInstallments = Math.min(this.pixSelectedInstallments, simulation.length || 1);
        this.$emit('select-pix-max-installments', minInstallments);
      })
      .catch(error => logging(error));
  }

  onSelectPixInstallments(installmentItem) {
    this.$emit('select-pix-max-installments', installmentItem.installments);
  }

  onSelectBankSlipInstallments(installmentItem) {
    this.$emit('select-bank-slip-max-installments', installmentItem.installments);
  }

  @Watch('offerPrice')
  onChangeOfferPrice() {
    this.fetchBankSlipSimulation();
    this.fetchPixSimulation();
  }

  @Watch('bankSlipInstallmentEnabled')
  onChangeBankSlipInstallmentStatus() {
    if (!this.bankSlipInstallmentEnabled) {
      this.$emit('select-bank-slip-max-installments', 1);
    }

    this.fetchBankSlipSimulation();
  }

  @Watch('pixInstallmentEnabled')
  onChangePixInstallmentStatus() {
    if (!this.pixInstallmentEnabled) {
      this.$emit('select-pix-max-installments', 1);
    }

    this.fetchPixSimulation();
  }
}
